import React, { useLayoutEffect, useRef } from 'react'
import { Link } from 'gatsby'
import { useApplicationContext } from '../provider'
import { Helmet } from 'react-helmet'

export default function Pages() {
  const { Cookies } = useApplicationContext()

  const homeRef = useRef(null)

  useLayoutEffect(() => {
    let origin =
      typeof window !== `undefined` &&
      window.__INITIALDATA__ &&
      window.__INITIALDATA__.isPaypal
    localStorage.setItem('origin', origin)

    return homeRef.current.click()
  }, [Cookies])
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="08AuxOFMudC-SlG1OO2LsJ6r9YjQhylNJ8gJnOQNV5M"
        />
      </Helmet>
      <Link ref={homeRef} to="/home" />
    </>
  )
}
